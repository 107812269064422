import React, { useState, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import {
  getFormattedDate,
  getFormattedFilename,
  getFormatFileSize,
} from '../utility';
import { fetchUserFiles, uploadFile } from '../services/fileService';

const ALLOWED_FILE_TYPES = {
  'application/pdf': 'pdf',
  'text/plain': 'txt',
  'text/csv': 'csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
};
const MAX_FILES_PER_TYPE = 5;

const Upload = () => {
  const queryClient = useQueryClient();
  const [file, setFile] = useState(null);
  const [directory, setDirectory] = useState('');
  const [error, setError] = useState('');

  const { data: userFiles = [], isLoading } = useQuery({
    queryKey: ['userFiles'],
    queryFn: fetchUserFiles,
  });

  const mutation = useMutation({
    mutationFn: async (file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('directory', directory);

      const response = await uploadFile(formData);

      if (response.error) {
        console.log(response, 'Full error response from server');
        throw new Error(response.message || 'Error uploading file');
      }

      return response;
    },
    onSuccess: (data) => {
      console.log(data, 'Upload response data');
      const message = data.message || 'File uploaded successfully';
      toast.success(message);
      queryClient.invalidateQueries(['userFiles']);
      setFile(null);
      setDirectory('');
    },
    onError: (error) => {
      console.error('Upload failed:', error.message);
      toast.error(error.message);
    },
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const fileType = ALLOWED_FILE_TYPES[selectedFile.type];

    if (!fileType) {
      setError('Please upload a PDF, TXT, CSV, XLSX, or DOCX file.');
      setFile(null);
      return;
    }

    const currentTypeCount = userFiles.filter((file) =>
      file.file.endsWith(fileType)
    ).length;

    if (currentTypeCount >= MAX_FILES_PER_TYPE) {
      setError(`Upload limit reached for ${fileType.toUpperCase()} files.`);
      setFile(null);
      return;
    }

    if (selectedFile.size > 1024 * 1024) {
      setError('File size must be less than 1MB.');
      setFile(null);
      return;
    }

    setError('');
    setFile(selectedFile);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (file) {
      mutation.mutate(file);
    } else {
      toast.error('Please select a valid file to upload.');
    }
  };

  const directories = useMemo(() => {
    const uniqueDirs = new Set(
      userFiles.map((file) => file.directory || 'Root')
    );
    return [...uniqueDirs];
  }, [userFiles]);

  return (
    <div className='w-full h-[calc(100vh-var(--custom-header-height))] px-16 py-8'>
      {isLoading ? (
        <p>Loading files...</p>
      ) : (
        <div className='flex flex-col items-center justify-center w-full h-full'>
          <div className='flex flex-col items-start justify-center w-full md:w-[600px]'>
            <p className='text-md mb-2 w-full text-left text-lg'>
              Please select a file to upload to your account.
            </p>
            <div className='flex items-center justify-start w-full md:w-96 gap-8'>
              <span className='w-96 bg-white px-4 py-3 rounded-lg h-12 border border-[#D9D9D9]'>
                {file ? file.name : 'no file selected!'}
              </span>
              <input
                type='file'
                id='file-upload'
                className='hidden'
                onChange={handleFileChange}
              />
              <label
                htmlFor='file-upload'
                className='my-5 h-10 bg-[var(--custom-bg-color)] text-white px-4 py-2 rounded-3xl text-sm font-semibold cursor-pointer'
              >
                Browse
              </label>
            </div>
            <input
              type='text'
              placeholder='Directory'
              value={directory}
              onChange={(e) => setDirectory(e.target.value)}
              className='w-full mt-2 px-3 py-2 border border-[#D9D9D9] rounded-lg'
            />
            <button
              className={`my-5 h-10 bg-[var(--custom-bg-color)] text-white px-4 py-2 rounded-3xl text-sm font-semibold ${
                file ? 'cursor-pointer' : 'cursor-not-allowed opacity-70'
              }`}
              onClick={handleSubmit}
              disabled={!file}
            >
              Upload
            </button>
          </div>

          {error && <p className='w-full text-red-500 my-2'>Error: {error}</p>}

          <div className='flex flex-col mt-8 space-y-2 w-full md:w-[600px]'>
            <p>Files in your account:</p>

            <div className='overflow-y-auto max-h-72 w-full'>
              <table className='min-w-full border-collapse shadow-md table-fixed'>
                <thead className='bg-[#863e39] text-white sticky top-0'>
                  <tr>
                    <th className='px-4 py-2 border w-1/2'>Filename</th>
                    <th className='px-4 py-2 border w-1/4'>Size</th>
                    <th className='px-4 py-2 border w-1/4'>Uploaded</th>
                  </tr>
                </thead>
                <tbody>
                  {userFiles.length === 0 ? (
                    <tr>
                      <td
                        colSpan='3'
                        className='text-center py-2'
                      >
                        No files found.
                      </td>
                    </tr>
                  ) : (
                    directories.map((dir) => (
                      <React.Fragment key={dir}>
                        <tr>
                          <td
                            colSpan='3'
                            className='bg-gray-200 font-semibold py-2'
                          >
                            Directory: {dir}
                          </td>
                        </tr>
                        {userFiles
                          .filter((file) => file.directory === dir)
                          .map((file) => (
                            <tr
                              key={file.id}
                              className='hover:bg-[#f1c4c2] even:bg-gray-100'
                            >
                              <td className='px-4 py-2 border truncate'>
                                {getFormattedFilename(file.file)}
                              </td>
                              <td className='px-4 py-2 border'>
                                {getFormatFileSize(file.file_size)}
                              </td>
                              <td className='px-4 py-2 border'>
                                {getFormattedDate(file.uploaded_at)}
                              </td>
                            </tr>
                          ))}
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Upload;
