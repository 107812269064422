import React, { useEffect, useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchApiKeys } from '../services/api';

const ChatInput = ({
  message,
  setMessage,
  apiKey,
  setAPIKey,
  handleSendMessage,
  isDisabled,
}) => {
  const { data: apiKeys } = useQuery({
    queryKey: ['apiKeys'],
    queryFn: fetchApiKeys,
  });

  const [isFirstMessage, setIsFirstMessage] = useState(true);

  const handleKeyPress = useCallback(
    (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        if (!isDisabled) {
          handleSendMessage();
          setIsFirstMessage(false);
        }
      }
    },
    [isDisabled, handleSendMessage]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleClickSend = () => {
    handleSendMessage();
    setIsFirstMessage(false);
  };

  useEffect(() => {
    if (apiKeys && apiKeys.length === 1) {
      setAPIKey(apiKeys[0].key);
    }
  }, [apiKeys, setAPIKey]);

  return (
    <div className='flex flex-col items-center justify-between mx-16 pb-16'>
      <textarea
        placeholder={
          isFirstMessage ? 'Start a conversation…' : 'Enter next message…'
        }
        className='w-full h-16 px-4 py-2 text-sm rounded-lg my-2 focus:outline-none'
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className='flex items-center justify-start gap-4 my-2'>
        <label
          htmlFor='API Key'
          className='font-normal w-12'
        >
          API Key
        </label>
        <select
          value={apiKey}
          onChange={(e) => setAPIKey(e.target.value)}
          className='focus:outline-none bg-white border border-[#D9D9D9] p-2 rounded-lg flex-1'
        >
          {apiKeys?.map((key) => (
            <option
              key={key.id}
              value={key.key}
            >
              {key.name || 'Unnamed Key'}
            </option>
          ))}
        </select>
      </div>
      <button
        className={`w-max md:w-36 font-semibold bg-[var(--custom-bg-color)] text-white px-2 md:px-3 py-2 rounded md:rounded-3xl ${
          isDisabled ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'
        }`}
        type='button'
        onClick={handleClickSend}
        disabled={isDisabled}
      >
        Send
      </button>
      <p className='text-xs text-gray-500 mt-2'>
        Press <strong>Ctrl + Enter</strong> (or <strong>⌘ + Enter</strong> on
        Mac) to send
      </p>
    </div>
  );
};

export default ChatInput;
