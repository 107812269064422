import { getCookie, setCookie } from '../utility';
import { toast } from 'react-hot-toast';

async function refreshCsrfToken() {
  try {
    const csrfToken = getCookie('csrftoken');
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    };

    const response = await fetch('api/auth/get-csrf-token', {
      method: 'GET',
      headers,
      credentials: 'include',
    });

    if (response.ok) {
      const data = await response.json();
      setCookie('csrftoken', data.csrf_token);
      console.log('CSRF token refreshed successfully.');
    } else {
      console.error('Failed to refresh CSRF token.');
      throw new Error('Failed to refresh CSRF token');
    }
  } catch (error) {
    console.error('Error refreshing CSRF token:', error);
    throw error;
  }
}

export async function apiFetch(url, options = {}) {
  const csrfToken = getCookie('csrftoken');
  const headers = {
    ...options.headers,
    'Content-Type': 'application/json',
    'X-CSRFToken': csrfToken,
  };

  let response;

  try {
    response = await fetch(url, {
      ...options,
      headers,
      credentials: 'include',
    });

    if (response.status === 403) {
      console.warn('403 error detected. Attempting to refresh CSRF token.');
      try {
        await refreshCsrfToken();
        headers['X-CSRFToken'] = getCookie('csrftoken');

        response = await fetch(url, {
          ...options,
          headers,
          credentials: 'include',
        });

        if (response.status === 403) {
          console.error('403 error after retry. Likely session expired.');
          toast.error('Your session has expired. Please log in again.');
          window.location.href = '/login';
          return;
        }
      } catch (csrfError) {
        console.error('Failed to refresh CSRF token:', csrfError);
        toast.error('Session expired. Please log in again.');
        window.location.href = '/login';
        return;
      }
    }

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'An error occurred.');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during API request:', error);
    throw error;
  }
}
