import React from 'react';

const SessionSelector = ({
  previousSessions,
  activeSessionId,
  handleSessionSwitch,
}) => {
  return (
    <div className='w-full flex flex-col items-start my-4'>
      <div className='flex flex-col gap-4 m-4 '>
        {previousSessions.map((session) => (
          <button
            key={session.session_id}
            className={`px-4 py-2 rounded ${
              activeSessionId === session.session_id
                ? 'bg-blue-500 text-white'
                : 'bg-gray-300'
            }`}
            onClick={() => handleSessionSwitch(session.session_id)}
          >
            {`Session: ${new Date(session.created_at).toLocaleString()}`}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SessionSelector;
