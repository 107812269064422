import axios from 'axios';
import { getCookie, setCookie } from '../utility';

const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

axios.defaults.withCredentials = true;

const refreshCsrfToken = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/auth/get-csrf-token/`, {
      withCredentials: true,
    });

    if (response.status === 200) {
      console.log(response.data, 'aaa');
      const newCsrfToken = response.data.csrf_token;
      setCookie('csrftoken', newCsrfToken);
      axios.defaults.headers.common['X-CSRFToken'] = newCsrfToken;
      console.log('CSRF token refreshed successfully.');
    } else {
      console.error('Failed to refresh CSRF token.');
      throw new Error('CSRF refresh failed.');
    }
  } catch (error) {
    console.error('Error refreshing CSRF token:', error);
    throw error;
  }
};

axios.interceptors.request.use(async (config) => {
  const csrfToken = getCookie('csrftoken');
  if (csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken;
  } else {
    await refreshCsrfToken();
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      console.warn('403 error. Refreshing CSRF token...');

      await refreshCsrfToken();

      originalRequest.headers['X-CSRFToken'] = getCookie('csrftoken');

      return axios(originalRequest);
    }

    return Promise.reject(error);
  }
);

export const fetchUserData = async () => {
  const response = await axios.get(`${baseUrl}/api/auth/user-data/`);
  return response.data;
};

export const fetchApiKeys = async () => {
  const response = await axios.get(`${baseUrl}/api/auth/api-keys/`);
  return response.data.api_keys;
};

export const addApiKey = async ({ key, name }) => {
  const csrfToken = getCookie('csrftoken');
  const response = await axios.post(
    `${baseUrl}/api/auth/api-keys/add/`,
    { key, name },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
      },
    }
  );
  return response.data;
};

export const setActiveApiKey = async (keyId) => {
  const csrfToken = getCookie('csrftoken');
  const response = await axios.post(
    `${baseUrl}/api/auth/api-keys/${keyId}/set-active/`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
      },
    }
  );
  return response.data;
};
