import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { useAuth } from '../../context/AuthContext';
import toast from 'react-hot-toast';
import { getCookie } from '../../utility';
import { apiFetch } from '../../services/apiService';

const Header = () => {
  const navigate = useNavigate();
  const { loggedInUser, clearUser } = useUser();
  const { setLogout } = useAuth();

  const handleBrandClick = () => {
    if (loggedInUser) {
      navigate('/home');
    } else {
      navigate('/');
    }
  };

  const handleLogout = () => {
    const logout = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const csrfToken = getCookie('csrftoken');
        const response = await apiFetch(`${baseUrl}/api/auth/logout/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          credentials: 'include',
        });

        if (response.error) {
          const errorData = await response;

          throw new Error(errorData.message || 'Logout failed');
        }
        clearUser();
        setLogout();
        toast.success('Logged out.');
        navigate('/');
      } catch (error) {
        toast.error('Something went wrong while logging out.');
        throw error;
      }
    };
    logout();
  };
  return (
    <div className='w-full bg-[var(--custom-bg-color)] h-[var(--custom-header-height)] px-8 py-2'>
      <div className='w-full h-full flex items-center justify-between'>
        <h1
          className='text-white font-normal uppercase text-2xl hover:cursor-pointer'
          onClick={handleBrandClick}
        >
          {' '}
          DARE
        </h1>
        <div className='flex items-center justify-center gap-5'>
          <p>
            <Link
              to={loggedInUser ? '/home' : '/'}
              className='font-normal transform-none text-white'
            >
              {loggedInUser ? `hello ${loggedInUser?.username}` : 'login'}
            </Link>
          </p>

          {loggedInUser?.username && (
            <p
              className='font-normal text-white cursor-pointer'
              onClick={handleLogout}
            >
              Logout
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
