import React, { createContext, useContext, useEffect, useState } from 'react';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState('');

  useEffect(() => {
    const storedLoggedInUser = localStorage.getItem('loggedInUser');
    if (storedLoggedInUser) {
      setLoggedInUser(JSON.parse(storedLoggedInUser));
    }
  }, []);

  const setUser = (value) => {
    setLoggedInUser(value);
    localStorage.setItem('loggedInUser', JSON.stringify(value));
  };

  const clearUser = () => {
    setLoggedInUser('');
    localStorage.removeItem('loggedInUser');
  };

  return (
    <UserContext.Provider value={{ loggedInUser, setUser, clearUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
