import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  fetchUserData,
  fetchApiKeys,
  addApiKey,
  setActiveApiKey,
} from '../services/api';

const Account = () => {
  const queryClient = useQueryClient();
  const [newApiKey, setNewApiKey] = useState('');
  const [apiName, setApiName] = useState('');

  const { data: userData } = useQuery({
    queryKey: ['userData'],
    queryFn: fetchUserData,
  });

  const { data: apiKeys, isLoading } = useQuery({
    queryKey: ['apiKeys'],
    queryFn: fetchApiKeys,
  });

  const addApiKeyMutation = useMutation({
    mutationFn: addApiKey,
    onSuccess: () => {
      queryClient.invalidateQueries(['apiKeys']);
      setNewApiKey('');
      setApiName('');
    },
  });

  const setActiveApiKeyMutation = useMutation({
    mutationFn: setActiveApiKey,
    onSuccess: () => {
      queryClient.invalidateQueries(['apiKeys']);
    },
  });

  const handleAddApiKey = () => {
    addApiKeyMutation.mutate({ key: newApiKey, name: apiName });
  };

  const handleSetActiveApiKey = (keyId) => {
    setActiveApiKeyMutation.mutate(keyId);
  };

  return (
    <div className='w-full h-screen px-16 py-8'>
      <h2 className='text-2xl font-bold mb-4'>Account Details</h2>

      <p className='text-md my-3 flex items-center'>
        <span className='font-normal w-36'>Username:</span>
        <span className='ml-1'>{userData?.username}</span>
      </p>
      <p className='text-md my-3 flex items-center'>
        <span className='font-normal w-36'>Email:</span>
        <span className='ml-1'>{userData?.email}</span>
      </p>

      <h3 className='text-xl font-semibold mt-8 mb-4'>API Keys</h3>

      {isLoading ? (
        <p>Loading API keys...</p>
      ) : (
        <ul>
          {apiKeys?.map((key) => (
            <li
              key={key.id}
              className='my-2 flex items-center gap-4'
            >
              <span className={key.is_active ? 'font-bold text-green-500' : ''}>
                {key.name || 'Unnamed'}: {key.key}
              </span>
              {!key.is_active && (
                <button
                  onClick={() => handleSetActiveApiKey(key.id)}
                  className='text-blue-500 underline'
                >
                  Set as Active
                </button>
              )}
              {key.is_active && <span className='text-gray-500'>(Active)</span>}
            </li>
          ))}
        </ul>
      )}

      <div className='mt-6'>
        <h4 className='font-medium mb-2'>Add New API Key</h4>
        <input
          type='text'
          value={apiName}
          onChange={(e) => setApiName(e.target.value)}
          placeholder='API name'
          className='border border-gray-300 p-2 rounded w-full mb-2'
        />
        <input
          type='text'
          value={newApiKey}
          onChange={(e) => setNewApiKey(e.target.value)}
          placeholder='Enter new API key'
          className='border border-gray-300 p-2 rounded w-full mb-2'
        />
        <button
          onClick={handleAddApiKey}
          className='px-4 py-2 bg-blue-500 text-white rounded'
          disabled={addApiKeyMutation.isLoading}
        >
          Add API Key
        </button>
      </div>
    </div>
  );
};

export default Account;
