import { getCookie } from '../utility';

export const fetchUserFiles = async () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const csrfToken = getCookie('csrftoken');
  const response = await fetch(`${baseUrl}/api/files`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Error fetching files');
  }

  return response.json();
};

export const uploadFile = async (formData) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const csrfToken = getCookie('csrftoken');

  const response = await fetch(`${baseUrl}/api/upload/`, {
    method: 'POST',
    headers: {
      'X-CSRFToken': csrfToken,
    },
    body: formData,
    credentials: 'include',
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Error uploading file');
  }

  return response.json();
};
