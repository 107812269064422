import React, { useEffect, useRef, useMemo } from 'react';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

const ChatMessages = ({ sessions, activeSessionId, isTyping }) => {
  const chatContainerRef = useRef(null);

  const currentSession = useMemo(() => {
    return sessions && sessions[activeSessionId]
      ? sessions[activeSessionId]
      : [];
  }, [sessions, activeSessionId]);

  // Scrolls to the bottom whenever the currentSession or typing status changes
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [currentSession, isTyping]);

  return (
    <div
      ref={chatContainerRef}
      className='h-[50vh] overflow-y-scroll border border-[#c9b5ac] px-8 mx-16 my-2 bg-white'
    >
      {currentSession.length > 0 ? (
        currentSession.map((entry) => (
          <div
            key={entry.key}
            className={classNames('my-2 p-2 rounded-lg max-w-[75%]', {
              'bg-gray-300 text-right ml-auto': entry.sender === 'You',
              'bg-blue-100 text-left mr-auto': entry.sender === 'Claude',
            })}
          >
            <strong>{entry.sender}:</strong>{' '}
            <ReactMarkdown>{entry.text}</ReactMarkdown>
          </div>
        ))
      ) : (
        <p>No messages yet</p>
      )}
      {isTyping && (
        <div className='text-left'>
          <strong>Claude:</strong>{' '}
          <span className='animate-pulse'>typing...</span>
        </div>
      )}
    </div>
  );
};

export default ChatMessages;
