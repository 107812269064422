import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import router from './pages/router';
import { UserProvider } from './context/UserContext';
import { AuthProvider } from './context/AuthContext';
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <UserProvider>
      <AuthProvider>
        <Toaster position='top-right' />
        <RouterProvider router={router} />
      </AuthProvider>
    </UserProvider>
  </QueryClientProvider>
);
