import React from 'react';
import { getFormattedFilename } from '../utility';

const FileSelection = ({ userFiles, selectedFileId, setSelectedFileId }) => {
  return (
    <div className='flex items-center justify-start gap-4 my-2'>
      <label
        htmlFor='File Selection'
        className='font-normal w-12'
      >
        File
      </label>
      <select
        value={selectedFileId}
        onChange={(e) => setSelectedFileId(e.target.value)}
        className='focus:outline-none bg-white border border-[#D9D9D9] p-2 rounded-lg flex-1'
      >
        <option value=''>Select a file...</option>
        {userFiles.map((file) => (
          <option
            key={file.id}
            value={file.id}
          >
            {getFormattedFilename(file.file)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FileSelection;
