import { getCookie } from '../utility';
import { apiFetch } from './apiService';

export const fetchSessions = async () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const csrfToken = getCookie('csrftoken');
  const response = await apiFetch(`${baseUrl}/claude/chat/sessions/`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    },
  });

  if (response.error) {
    throw new Error(`Error: ${response.statusText}`);
  }

  return response;
};
